import { createDocumentResource, createQueryResource, list } from 'retil';
import { Entry } from '@2022-sophians-web/components/src/types';

export const entry = createDocumentResource<Entry>('entry');
export const entryList = createQueryResource('entryList', {
    for: list(entry),
    load: async () => {
        return [
            {
                id: 1,
                entry_id: 1,
                block: 'A',
                stage: '1',
                name: '藤原 美羽',
                "faculty": "総合人間科学部 \n社会福祉学科",
                "course": "",
                "grade": "2年",
                "birthday": "2003/02/20",
                "hometown": "岡山県",
                "hobby": "趣味:料理・カフェ巡り・旅行 \n特技:料理を作ること(小学３年生の時から料理を習っていました！)・書道・フラワーアレンジメント・バレーボール",
                twitter: "",
                instagram: "sophians2022_no.1",
                interview1: "２年前から上智大学はコンテストの理念が変わり、社会課題を発信する場を設けているという点に高校生の頃から興味をもっていました。昨年もソフィアンズコンテストに応募させて頂いたのですが今年、コンテストの推薦を頂いたことで背中を押され、もう一度挑戦することを決めました。これまで行ってきたカンボジアをフィールドとした課題研究活動や８月のアメリカ渡航での学びを自身の中で完結するのではなく、その学びを活かし、学校や社会を巻き込みながら社会課題を発信していきたいと思ったからです。",
                interview2: "社会課題の発信を積極的に行い、自分自身の考えや理解を深めたいです。また、サークルや企業、学生団体とのコラボにも力を入れていきたいです！",
                interview3: "途上国と先進国における環境問題と貧困問題の現状と今の私たちにできること(高校でカンボジアやインドへ渡航し、スラム街や農村地域などで現地調査やボランティア活動を行ってきました。この経験や学びを活かし、一人でも多くの方に社会課題が身近にあることを感じて頂きたいです。)",
                interview4: "行動力と継続力です！また、コンテスト期間中にアメリカへ渡航し、現地でボランティア活動を行いながら、リアルタイムな情報をSNSで発信するというこれまでのソフィアンズコンテストにはなかった新たな形で発信します！",
                interview5: "フットサルサークル",
                interview6: "スターバックスコーヒージャパン・会席料理店(着物を着て料理を運んでいます！)",
                interview7: "目標や夢を実現させるために学生団体に入ったり、事業を起こしたり、留学へ行ったりと様々なことに挑戦している人が多いため、「自分も夢を叶えるために何か行動しよう！」と気力を奮い立たせてくれる環境であること。",
                interview8: "映画を観ること・スターバックスのドリンクのカスタムを考えること",
                interview9: "曾祖母と曾祖父(曾祖母は９４歳で亡くなる直前まで10人家族の私たちのご飯を作ってくれていました。日々私たち家族の健康を考え、新しいレシピに挑戦したり、旬の食材を使った料理を食べさせてくれました。そんないつまでも今の自分に甘んじることなく、挑戦し続けていた大正生まれの気丈な曾祖母と昨年１０１歳で亡くなった曾祖父はコミュニケーション能力が高くいつも人が周りに集まる楽しい曾祖父でした。海外へ行ってもすぐに現地の方達の輪に入り仲良くなることが得意な行動力のある曾祖父が私の尊敬する人です。)",
                interview10: "「実るほど頭を垂れる稲穂かな」（私の祖母が祖父の元に嫁いだ時に曾祖父から言われて仕事をする上でとても大切にしている言葉だと母から私にも受け継がれている言葉です）",
                interview11: "今まで自分がカンボジアなどで経験してきたことや活動、調べたことを一人でも多くの方に伝えることで、世界で生じている様々な課題を知って頂く機会にしたいと思っています。更にアメリカでボランティア活動を行いながら現地からのリアルタイムな情報をお伝えしたいと思っています。実行委員とファイナリスト５人で協力し、これまで以上の素敵なコンテストを創っていきますので、応援よろしくお願い致します！",
            },
            {
                id: 2,
                entry_id: 2,
                block: 'A',
                stage: '1',
                name: '谷川 咲麗子',
                "faculty": "総合人間科学部 \n社会福祉学科",
                "course": "",
                "grade": "3年",
                "birthday": "2002/03/15",
                "hometown": "東京都",
                "hobby": "趣味: 映画鑑賞、音楽鑑賞、猫とまったり \n特技: ドラム、チアダンス",
                twitter: "",
                instagram: "sophians2022_no.2",
                interview1: "祖父の難病発症をきっかけに、高齢や障害、難病などによって日常生活に課題を抱える当事者の孤立や孤独のない社会、可能性が失われることのない社会に向けて、自分にできることから始めようとこれまで様々な活動をしてきましたが、当コンテストを通じて発信をすることで、一人でも多くの方に関心を持っていただければと思ったからです。また、自分自身の経験や視野を広げていきたいと思っています。",
                interview2: "私はこれまであまりSNSをしておらず見る側にいましたが、これからたくさんの方と繋がれるよう、自分の思いを知ってもらえるよう、不慣れながらもSNSでの発信を頑張っていきたいと思います。",
                interview3: "私の祖父は、指定難病であるALS（筋萎縮性側索硬化症）を患ったことにより突如介護される側になってしまいましたが、ALSの講演会での講義を依頼されたことから言語のリハビリなどを懸命に行うようになりました。このときの経験から、祖父の懸命に生きる姿を目の当たりにし、当人が社会参加の可能性を知ることによって生きがいや生きる意欲を持ちうることを知りました。祖父から学んだことや、当事者へのインタビューなどの活動を通じて、当事者の社会参加の重要性を多くの方々に伝えていきたいと思います。",
                interview4: "不可能と思われる事もあきらめない行動力です。また、周りを和ます力です。私自身少し抜けているところがあるのでよく周りの方々が笑ってくれます。",
                interview5: "",
                interview6: "カフェ",
                interview7: "設備が整っていて学びやすい環境であることと、夜のキャンパスが綺麗なところです。私の所属している総合人間科学部は、教育や心理、社会や社会福祉、看護など、多方面から人間の尊厳について考えることができ、他の学科の授業も受講できるため学びの幅が広がり、広い視野を手に入れることができます。",
                interview8: "プロジェクターで映画やYouTubeを観ることです。癒しの時間です。",
                interview9: "家族、オードリー・ヘプバーン（祖母から3代にわたって憧れの女性です）、有村架純さん（笑顔が素敵なので）",
                interview10: "有言実行",
                interview11: "私にとって初めての大きな挑戦となりますが、皆様が社会問題に興味を持つきっかけとなれるよう精一杯頑張ります。私自身全ての出会いは必然だと考えているので、出会いを大切に、出会いに感謝しながら挑戦していきたいと思っています。応援よろしくお願いします。",
            },
            {
                id: 3,
                entry_id: 3,
                block: 'A',
                stage: '1',
                name: '河合 実卯',
                "faculty": "国際教養学部 \n国際教養学科",
                "course": "",
                "grade": "4年",
                "birthday": "2001/03/02",
                "hometown": "埼玉県",
                "hobby": "ウォーキング、美術館巡り、カフェ巡り、いろんな形の表現に触れること！",
                twitter: "",
                instagram: "sophians2022_no.3",
                interview1: "従来のミスコン/ミスターコンとは違い、美しさや女らしさなどを基準とせず、性別にとらわれない形や社会活動などを基準としているこのコンテストに惹かれました。自らコンテストに参加し、活動することでこのコンテストを広めたいという思いから参加を決意しました。また、自分自身のSNSやプラットフォームだけでは届けられる人の幅に制限を感じていたため、コンテストを通して、私の力だけでは届けられない人々にも自分の発信を届けられると思ったのも理由の一つです。",
                interview2: "SNSを通して、\"自分にしかできない発信\"をすることにこだわりたいと思っています。今までSNSに力を入れてきた私だからこそできる発信に力を入れ、誰かの小さなきっかけや行動に繋がるよう頑張りたいと思います！",
                interview3: "「女/男はこうでなければならない」「大学を卒業したら一般企業に就職するものだ」など、日本社会にはたくさんの染みついた考えや価値観があると感じています。一人一人の個性や自分らしい生き方というものが少しずつ認められてきてはいますが、大学に入り、多様なバックグラウンドを持つ人々の価値観に触れるようになったことで、そこで、私がインタビューなどの活動を通して、様々な人の考えや価値観に触れ、発信することで、日本社会に染みついた価値観を少しでも崩し、『多様性や一人一人の違いが認められることの大切さ』を伝えていきたいと思っています。",
                interview4: "\"自己表現力\"と\"発想力\"には自信があります！小さい頃からダンス(フラダンス、チアダンス、ストリートダンス)や音楽(吹奏楽とジャズバンド)、モデルウォーキングなどと様々な表現に触れてきた私だからこそできる自己PRステージをつくりたいと思っています！",
                interview5: "今は引退してしまいましたが、3年の頃まではG-splashというストリートダンスサークルでハウスを踊っていました",
                interview6: "居酒屋",
                interview7: "お互いの違いや個性を否定することなく、認め合える環境",
                interview8: "自分で企画から撮影まで行い、作品をつくることです！",
                interview9: "家族、切磋琢磨することで一緒に上を目指し続けてくれる友人",
                interview10: "\"One voice can inspire others, one voice can start the movement.\" \n意味: 一つの声が少しずつ広がり、大きな運動となる\n私の活動や発信することが誰かのきっかけや周りの人々を巻き込むことに繋がればと思っております！",
                interview11: "ソフィアンズコンテストのファイナリストとしての自覚と責任を持ち、最後まで自分らしく活動していきたいと思っています！自分の活動や発信を通して、自身の魅力だけでなく、このコンテストの魅力も伝えていければと思います。ファイナリスト5人で頑張りますので、応援よろしくお願いします！！",
            },
            {
                id: 4,
                entry_id: 4,
                block: 'A',
                stage: '1',
                name: '菅野 吏紗',
                "faculty": "文学部 \n新聞学科",
                "course": "",
                "grade": "4年",
                "birthday": "2000/09/15",
                "hometown": "東京都",
                "hobby": "趣味はライブに行くこと、愛犬と遊ぶこと、映画鑑賞\n特技は朝食をたくさん食べることです",
                twitter: "",
                instagram: "sophians2022_no.4",
                interview1: "コンテストの趣旨に惹かれました。学生最後の挑戦の場にさせていただきたく応募しました。自分の経験から見えにくい課題を伝えることで、発見や関心を広げたいと考えました。",
                interview2: "社会課題や活動の発信に力を入れたいです。発信を通して、さまざまな立場の人に思いを馳せたり、自身の経験や思いと向き合ったりする時間を提供できるように頑張ります。",
                interview3: "私自身の食物アレルギーの経験から、「食の多様性」を軸にお伝えします。食の多様性のお話から、「普通」や「違い」にとらわれない社会、健康や嗜好に合った食事の選択肢について発信していきます。",
                interview4: "眠れば心身の元気が全回復するところです。",
                interview5: "放送プロデュース研究会(B-pro)",
                interview6: "映画館で接客のアルバイト、報道機関で編集補助のアルバイトをしています。",
                interview7: "上智大学には色々なバックグラウンドを持つ学生がいて、興味の幅を広げるきっかけが多いです。新聞学科ではメディアのことを専門的に学び、社会課題と真剣に向き合うことができます。私のゼミでは、取材や撮影、文章の執筆など実践的な学びをしています。それぞれが頑張っていることを応援し合える素敵な仲間や教授と出会えることが最大の魅力です！",
                interview8: "ホットヨガ",
                interview9: "女優の杏さん、私の祖母",
                interview10: "雲の上はいつも晴れ",
                interview11: "常に自分にできることを考え、勇気を持って行動します！見てくださっている方々の豊かさに少しでも貢献できる存在になれるよう、頑張ります。ファイナリスト５人と実行委員、一丸となってコンテストを作り上げていきますので、応援よろしくお願いいたします！",
            },
            {
                id: 5,
                entry_id: 5,
                block: 'A',
                stage: '1',
                name: '石田 翔太',
                "faculty": "外国語学部 \n英語学科",
                "course": "",
                "grade": "2年",
                "birthday": "2002/10/15",
                "hometown": "千葉県",
                "hobby": "趣味は写真を撮ることや行ったことのない場所にいくこと。特技はサッカーです！",
                twitter: "",
                instagram: "sophians2022_no.5",
                interview1: "日本を変えたいと思ったから。",
                interview2: "周りに見られて怖い、恥ずかしいからやりたいことができないという人が、少しでも何かに「挑戦」できるような環境作りを頑張りたいです。",
                interview3: "「違いとは面白い」ということ。",
                interview4: "周りに流されず、自分の道を歩めるので、行動力があることです。",
                interview5: "体育会系バレーボール部、手話サークル",
                interview6: "塾講師、放課後デイサービス",
                interview7: "大学:キャンパスが小さい分、友達や知り合いに出会いやすいこと！\n学部:帰国子女や留学生など多様なバックグラウンドを持った学生が多いので、みんなフレンドリーで接しやすいことです！",
                interview8: "一人〇〇をすること",
                interview9: "有田哲平さん",
                interview10: "思い立った日が吉日",
                interview11: "コンテストの活動を通して、自分だけではなく、一人でも多くの方が自分の居たいと思う居場所を見つけ、「違い」を「個性」として表現できるような環境を整えていきたいです。全力で頑張りますので、見ていてください。",
            }
        ];
    },
});
